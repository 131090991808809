import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '@/templates/Main/Main.module.scss';
import Arrow from '@/components/Arrow/Arrow';
import Slider from '@/components/Slider/Slider';
import classNames from 'classnames';
import { Picture } from '@/components/Picture';

function MainSlides(props) {

	const { slides } = props;

	const [page, setPage] = useState(0);

	const [slidesWidth, setSliderWidth] = useState(1140);

	const sliderContainerRef = useRef(null);

	const resizeDebounceTimer = useRef(null);

	const isFirstPage = page === 0;

	const isLastPage = page >= slides.length - 1;

	const prevPage = useCallback(() => {
		if (isFirstPage) {
			return;
		}
		setPage((page) => page - 1);
	}, [isFirstPage]);

	const nextPage = useCallback(() => {
		if (isLastPage) {
			return;
		}
		setPage((page) => page + 1);
	}, [isLastPage]);

	const handleWindowResize = useCallback(() => {
		clearTimeout(resizeDebounceTimer.current);

		resizeDebounceTimer.current = setTimeout(() => {
			let sliderWidth = sliderContainerRef.current.clientWidth;
			if (sliderWidth > 428) {
				sliderWidth = Math.min(Math.max(sliderWidth, 1024 - 60), 1200 - 60);
			}
			if (sliderWidth <= 428) {
				sliderWidth = Math.min(Math.max(sliderWidth, 310), 428);
			}
			if (slidesWidth !== sliderWidth) {
				setSliderWidth(sliderWidth);
			}
		}, 50);

	}, [slidesWidth]);

	useEffect(() => {
		let sliderWidth = sliderContainerRef.current.clientWidth;
		if (sliderWidth > 428) {
			sliderWidth = Math.min(Math.max(sliderWidth, 1024 - 60), 1200 - 60);
		}
		if (sliderWidth <= 428) {
			sliderWidth = Math.min(Math.max(sliderWidth, 310), 428);
		}
		setSliderWidth(sliderWidth);

		if (typeof window !== 'undefined') {

			window.addEventListener('resize', handleWindowResize, { passive: true });

			return () => {
				clearTimeout(resizeDebounceTimer.current);
				window.removeEventListener('resize', handleWindowResize);
			};

		}
	}, [handleWindowResize]);

	return (
		<div className={styles.sliderContainer}>
			<div className={styles.arrows}>
				{!isFirstPage && (
					<Arrow
						onClick={prevPage}
						className={styles.arrowLeft}
						bigOne
						direction={'left'}
					/>
				)}
				{!isLastPage && (
					<Arrow
						onClick={nextPage}
						className={styles.arrowRight}
						bigOne
						direction={'right'}
					/>
				)}
			</div>
			<div
				className={styles.slider}
				ref={sliderContainerRef}
			>
				<Slider
					sense={50}
					page={page}
					onPageChange={setPage}
					itemWidth={slidesWidth}
				>
					{slides.map((slide, index) => {
						const slideClassName = classNames({
							[styles.slide]: true,
						});

						return <div key={index} className={slideClassName}>
							<Picture
								desktopType={'fullscreen-desktop'}
								mobileType={'fullscreen-mobile'}
								className={styles.image}
								image={slide.image.image}
								link={slide.link.type === 2 ? slide.link.linkedPage : slide.link.linkedPage?.fullPath}
								alt={slide.image.imageAlt}
								lazy={index !== 0}
								preload={index === 0}
							/>
						</div>;
					})}
				</Slider>
			</div>
		</div>
	);
}

export default React.memo(MainSlides);
