import React, { useEffect } from 'react';
import AppointmentForm from '@/components/AppointmentForm/AppointmentForm';
import Modal from '@/components/Modal';
import SalonsListController from '@/components/SalonsListController/SalonsListController';
import ServicesPicker from '@/components/ServicesPicker/ServicesPicker';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getPage, getSalons } from '@/store/selectors';
import { setAppointmentSalonAction, setSelectedCityIdAction } from '@/store/actions';

function AppointmentFormController({ onSubmit }) {
	const [stage, setStage] = React.useState('form'); // form, salon, services
	const [servicesClientIndex, setServicesClientIndex] = React.useState(0);
	const history = useHistory();
	const salons = useSelector(getSalons);
	const dispatch = useDispatch();

	const setFormStage = React.useCallback(() => {
		setStage('form');
	}, []);

	const handleSelectSalon = React.useCallback((salonId) => {
		const salon = salons.find(({ id }) => id === salonId);
		if (!salon) {
			return;
		}
		dispatch(setSelectedCityIdAction(salon.cityId));
		setFormStage();
	}, [setFormStage, salons, dispatch]);

	const page = useSelector(getPage);

	useEffect(() => {
		if (page && page.template === 'salon-page' && page.templateVars?.salon?.id) {
			dispatch(setAppointmentSalonAction(page.templateVars.salon.id));
			handleSelectSalon(page.templateVars.salon.id);
		}
	}, [page, handleSelectSalon, dispatch]);

	switch (stage) {
		case 'form':
			return (
				<Modal
					isOpen
					title={'Записаться'}
					onClose={() => history.replace('#')}
				>
					<AppointmentForm
						onChooseServices={(clientIndex) => {
							setServicesClientIndex(clientIndex);
							setStage('services');
						}}
						onChooseSalon={() => setStage('salon')}
						onSubmit={onSubmit}
					/>
				</Modal>
			);
		case 'salon':
			return (
				<Modal
					isOpen
					title={'Выберите салон'}
					back={setFormStage}
					onClose={setFormStage}
				>
					<SalonsListController
						onSelectSalon={handleSelectSalon}
						lazy={false}
						listPageSize={4}
					/>
				</Modal>
			);
		case 'services':
			return (
				<Modal
					isOpen
					title={`Выберите услуги для гостя ${servicesClientIndex + 1}`}
					back={setFormStage}
					onClose={setFormStage}
				>
					<ServicesPicker
						clientIndex={servicesClientIndex}
						onSubmit={setFormStage}
					/>
				</Modal>
			);
	}

	return null;
}

export default AppointmentFormController;
